import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const BlowDry = () => {
  return (
    <div id="blowdrys" className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100  background-colour-beige-3">
          <div className="col-md-6 background-image background-blowdry"></div>
          <div className="col-md-6"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6">
            <div className="spacer-background-image"></div>
          </section>
          <section className="col-md-6 p-5">
            <h2>Blowdry</h2>
            <h4>
              Whether is date night or just because, when our hair feels
              amazing.. we feel amazing! Let me style your hair the way you want
              it.
            </h4>
            <p>
              Basin- Recline back in our beautiful massage chair while I hand
              select the perfect shampoo and conditioner to suit your individual
              hair needs. You could even treat yourself to a specialised
              treatment for that extra touch of love and make your hair sing!!
            </p>
            <p>
              Massage- The ultimate experience with my signature head massage to
              completely relax and rejuvenate.
            </p>
            <p>
              Relax- Sit back while I create your perfect style and enjoy your
              favourite drink and treat, with great tunes and conversation.
            </p>
            <p>
              Finish- I create those finishing touches to give you the perfect
              style!
            </p>
            <a
              href={BOOKING_LINK}
              rel="noreferrer"
              target="_blank"
              className="links"
            >
              Book A Blowdry Appointment
            </a>
          </section>
        </div>
      </div>
    </div>
  )
}

export default BlowDry
